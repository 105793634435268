<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="标题">
          <el-input style="width: 200px" clearable v-model.trim="searchData.title" size="mini" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-button style="margin-bottom: 10px" icon="el-icon-plus" plain type="primary" size="mini" @click="handleAdd">新增</el-button>
    <el-table :data="list">
      <el-table-column prop="title" label="标题" min-width="150"> </el-table-column>
      <el-table-column prop="content" label="解决方案" min-width="200"> </el-table-column>
      <el-table-column prop="sort" label="排序" min-width="100"> </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>
          <el-link :underline="false" type="danger" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />
    <!-- 新增编辑弹窗 -->
    <el-dialog :title="formData.id ? '编辑' : '新增'" :visible.sync="show_update" width="">
      <el-form :model="formData" ref="form" label-width="80px" size="mini">
        <el-form-item label="标题" prop="title" :rules="rules">
          <el-input style="width: 350px" clearable v-model="formData.title"></el-input>
        </el-form-item>
        <el-form-item label="解决方案" prop="content" :rules="rules">
          <el-input style="width: 350px" rows="7" type="textarea" clearable v-model="formData.content"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number  v-model="formData.sort" :step="1" :min="0" step-strictly></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="show_update = false">取消</el-button>
        <el-button size="small" type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getListAPI, delAPI, getDetailAPI, addAPI, editAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_update: false,
      list: [],
      searchData: {
        page: 1,
        page_size: 10,
        title: ''
      },
      total: 0,
      formData: {
        title: '',
        content: '',
        sort: 0
      }
    }
  },

  watch: {},
  activated() {},
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const res = await getListAPI(this.searchData)
      this.list = res.data || []
      this.total = res.total || 0
    },
    async handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.show_update = false
              this.getList()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.show_update = false
              this.getList()
            })
          }
        }
      })
    },
    //  add
    handleAdd() {
      this.formData = {
        title: '',
        content: '',
        sort: 0
      }
      this.show_update = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    // edit
    async handleEdit({ id }) {
      this.show_update = true
      this.$nextTick(async () => {
        this.formData = await getDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
  }
}
</style>
